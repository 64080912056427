import React from "react"
import { useTranslation } from "react-i18next"
import {
  title,
  container,
  history,
  details,
  header,
} from "../styles/history.module.css"
import Layout from "./Layout"

import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image/dist/src/components/gatsby-image.browser"

interface HistoryData {
  edges: Array<{ node: HistoryNode }>
}

interface HistoryNode {
  date: string,
  description: { description: string },
  optionalTitle: string,
  image: { gatsbyImageData: IGatsbyImageData }
}

const History = () => {
  const { i18n, t } = useTranslation()
  const data: HistoryData = useStaticQuery(graphql`{
    de_content: allContentfulHistory(
    filter: {node_locale: {eq: "de-DE"}}
    sort: {fields: date, order: DESC}
    ) {
      ...HistoryContent
    }
    ba_content: allContentfulHistory(
    filter: {node_locale: {eq: "bs-BA"}}
    sort: {fields: date, order: DESC}
    ) {
      ...HistoryContent
    }
  }
  `)
  var content: HistoryData = data[i18n.language + "_content"]

  return (
    <Layout>
      <h1 className={title}>{t("History")}</h1>
      <div className={container}>
        {content.edges &&
          content.edges.map((node, i) => {
            let item: HistoryNode = node.node
            let date: Date = new Date(item.date)
            let dateString: string = date.toLocaleString(i18n.language == "ba" ? "bs-BA" : "de-DE", { month: 'long', year: 'numeric' })
            dateString = dateString.charAt(0).toUpperCase() + dateString.substring(1)
            return (
              <div className={history} key={i}>
                <div className={details}>
                  <h3 className={header}>{dateString}</h3>
                  <p>{item.description.description}</p>
                </div>
                <GatsbyImage
                  alt={t("HISTORY_IMAGE_ALT")}
                  image={item.image.gatsbyImageData}
                  imgStyle={{ objectFit: "none", objectPosition: "50% 50%" }}
                />
              </div>
            )
          })}
      </div>
    </Layout>
  )
}

export default History
