import React, { useState } from "react"
import Page from "../components/Page"
import map from "../images/map_transparent.png"
import History from "../components/History"
import News from "../components/News"
import About from "../components/About"

import {
  container,
  menu,
  button,
  buttonSelected,
} from "../styles/about.module.css"
import Seo from "../SEO"
import { useTranslation } from "react-i18next"

const AboutUs = () => {
  const [t] = useTranslation()
  const [tab, setTab] = useState(0)

  const handleTabChange = n => setTab(n)

  return (
    <Page>
      <Seo
        title="Über uns - Das Team hinter DeBaCode"
        description="Ihre Anwendung in guten Händen: Ob Apps für mobile Endgeräte oder komplexe Prozessoptimierungen, unsere Expert:Innen entwickeln mit neusten Technologien."
        keywords={[
          "Team",
          "Ehrenamtlich",
          "Gemeinnützig",
          "Sozial",
          "Experten",
          "KIT",
        ]}
      ></Seo>
      <div
        className={container}
        style={tab === 0 ? { backgroundImage: `url(${map})` } : {}}
      >
        <div className={menu}>
          <div>
            <button
              onClick={e => {
                e.preventDefault()
                handleTabChange(0)
              }}
              className={`${button} ` + (tab === 0 && `${buttonSelected}`)}
            >
              {t("Team")}
            </button>
            <button
              onClick={e => {
                e.preventDefault()
                handleTabChange(1)
              }}
              className={`${button} ` + (tab === 1 && `${buttonSelected}`)}
            >
              {t("History")}
            </button>
            <button
              onClick={e => {
                e.preventDefault()
                handleTabChange(2)
              }}
              className={`${button} ` + (tab === 2 && `${buttonSelected}`)}
            >
              {t("News")}
            </button>
          </div>
        </div>
        {tab === 0 && <About />}
        {tab === 1 && <History />}
        {tab === 2 && <News />}
      </div>
    </Page>
  )
}

export default AboutUs
