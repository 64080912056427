import React from "react"
import { useTranslation } from "react-i18next"
import {
  container,
  titleStyle,
  newsStyle,
  textWrapper,
  alternateTextWrapper,
  heading,
  date,
  alternateDate,
} from "../styles/news.module.css"
import Layout from "./Layout"
import { graphql, useStaticQuery } from "gatsby"

interface NewsData {
  edges: Array<{ node: NewsNode }>
}

interface NewsNode {
  date: string,
  description: string,
  title: string
}

export default function News() {
  const { i18n, t } = useTranslation()
  const data: NewsData = useStaticQuery(graphql`
        {
          de_content: allContentfulNews(
            filter: { node_locale: { eq: "de-DE" } }
            sort: { fields: date, order: DESC }
          ) {
            edges {
              node {
                date(formatString: "DD-MM-yyyy")
                description
                title
              }
            }
          }
          ba_content: allContentfulNews(
            filter: { node_locale: { eq: "bs-BA" } }
            sort: { fields: date, order: DESC }
          ) {
            edges {
              node {
                date(formatString: "DD-MM-yyyy")
                description
                title
              }
            }
          }
        }
      `)
  let content: NewsData = data[i18n.language + "_content"]
  return (
    <Layout>
      <div className={container}>
        <h1 className={titleStyle}>{t("News")}</h1>
        {content.edges &&
          content.edges.map((node, i) => {
            let item: NewsNode = node.node
            let day: string = item.date.substring(0, 2)
            let month: string = item.date.substring(3, 5)
            let year: string = item.date.substring(6)
            return (
              <div className={newsStyle} key={i}>
                <div
                  className={
                    `${textWrapper}` +
                    (i % 2 === 1 ? ` ${alternateTextWrapper}` : ``)
                  }
                >
                  <h1 className={heading}>{item.title}</h1>
                  <p>{item.description}</p>
                </div>
                <h2
                  className={
                    `${date}` + (i % 2 === 1 ? ` ${alternateDate}` : ``)
                  }
                >
                  {day}.{month}
                  <br />
                  {year}
                </h2>
              </div>
            )
          })}
      </div>
    </Layout>
  )


}
