import React from "react"
import Layout from "../components/Layout"
import HeroTitle from "../components/HeroTitle"

import { lgView, smView, paragraph, title } from "../styles/about.module.css"
import { useTranslation } from "react-i18next"
import OurTeamPersonList from ".//OurTeamPersonList.js"

const About = () => {
  const [t] = useTranslation()
  return (
    <>
      <Layout>
        <div className={lgView}>
          <HeroTitle text={t("Social software")} />
        </div>
        <div className={smView}>
          <HeroTitle text={t("Social software mobile")} />
        </div>
        <p className={paragraph}>{t("About us Paragraph")}</p>
        <h1 className={title}>{t("Our team")}</h1>
        <OurTeamPersonList />
      </Layout>
    </>
  )
}

export default About
