import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { subtitle, container } from ".//OurTeamPersonList.module.css"
import { useTranslation } from "react-i18next"
import Avatar from ".//Avatar.js"

export default function ContactPersonList() {
  const { i18n } = useTranslation()
  return (
    <StaticQuery
      query={graphql`
        {
          de_germanStudents: contentfulStudentList(
            identifier: { eq: "german-students-list" }
            node_locale: { eq: "de-DE" }
          ) {
            ...StudentListWithNamePostitionImage
            title
          }
          ba_germanStudents: contentfulStudentList(
            identifier: { eq: "german-students-list" }
            node_locale: { eq: "bs-BA" }
          ) {
            ...StudentListWithNamePostitionImage
            title
          }
          de_bosnianStudents: contentfulStudentList(
            identifier: { eq: "bosnian-students-list" }
            node_locale: { eq: "de-DE" }
          ) {
            ...StudentListWithNamePostitionImage
            title
          }
          ba_bosnianStudents: contentfulStudentList(
            identifier: { eq: "bosnian-students-list" }
            node_locale: { eq: "bs-BA" }
          ) {
            ...StudentListWithNamePostitionImage
            title
          }
        }
      `}
      render={data => {
        const germanStudentList = data[i18n.language + "_germanStudents"]
        const bosnianStudentList = data[i18n.language + "_bosnianStudents"]
        return (
          <div>
            <h2 className={subtitle}>{germanStudentList.title}</h2>
            <div className={container}>
              {germanStudentList.studentList.map(node => {
                return (
                  <Avatar
                    name={node.name}
                    image={node.image}
                    position={node.position}
                  />
                )
              })}
            </div>
            <h2 className={subtitle}>{bosnianStudentList.title}</h2>
            <div className={container}>
              {bosnianStudentList.studentList.map(node => {
                return (
                  <Avatar
                    name={node.name}
                    image={node.image}
                    position={node.position}
                  />
                )
              })}
            </div>
          </div>
        )
      }}
    />
  )
}
