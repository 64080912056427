// extracted by mini-css-extract-plugin
export var alternateDate = "news-module--alternateDate--jw7eG";
export var alternateTextWrapper = "news-module--alternateTextWrapper--hlJsa";
export var container = "news-module--container--r1MJe";
export var date = "news-module--date--DkfnJ";
export var heading = "news-module--heading--MD-F7";
export var news = "news-module--news--73axG";
export var newsStyle = "news-module--newsStyle--hALcd";
export var textWrapper = "news-module--textWrapper--j6ZOI";
export var title = "news-module--title--npmir";
export var titleStyle = "news-module--titleStyle--tpQtk";